var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h5',[_vm._v("دخول العامة")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",staticStyle:{"color":"white"},attrs:{"color":"#098064","small":"","loading":_vm.IsLoading},on:{"click":function($event){return _vm.dialogModal(true, 'create','people')}}},'v-btn',attrs,false),on),[_vm._v(" تصدير "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-file-excel ")])],1)]}}])},[_c('span',[_vm._v(" تصدير الاشخاص والمركبات ")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.generals,"options":_vm.options,"server-items-length":_vm.generalsTotal,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions:[10, 20, 50, 100],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText'),
      itemsPerPageAllText: _vm.$t('dataTable.itemsPerPageAllText'),
    },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('datatable-header',{attrs:{"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"item.user.name",fn:function(ref){
    var item = ref.item;
return [(item.user)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.user.name)+" ")]):_vm._e()]}},{key:"item.people.identifier.name",fn:function(ref){
    var item = ref.item;
return [(item.people.identifier)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.people.identifier.name)+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [(item.created_at)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("format_date")(item.created_at))+" ")]):_vm._e()]}},{key:"item.car.expire_date",fn:function(ref){
    var item = ref.item;
return [(item.car.expire_date)?_c('v-chip',{attrs:{"color":Date.now() > new Date(item.car.expire_date) ? 'error': '',"label":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("format_date")(item.car.expire_date))+" ")]):_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" لايوجد ")])]}},{key:"item.people.name",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"text-wrap my-2 text-center",style:({height: 'fit-content', 'display': 'block'}),attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.people.name)+" ")])]}},{key:"item.enter",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.enter ? 'success' : 'error',"label":"","small":""}},[_vm._v(" "+_vm._s(item.enter ? 'نعم' : ' كلا')+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"tile":"","small":"","color":"info"},on:{"click":function($event){return _vm.dialogModal(true, 'update', item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" "+_vm._s(_vm.$('update'))+" ")],1),_c('v-btn',{attrs:{"tile":"","small":"","color":"error"},on:{"click":function($event){return _vm.dialogModal(true, 'delete', item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" "+_vm._s(_vm.$('delete'))+" ")],1)]}}],null,true)}),_c('export-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogData,"translation-key":_vm.translationKey},on:{"update:table":_vm.fetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }